@keyframes loading-content-animation {
    0%   { opacity:1; }
    50%  { opacity:0.25; }
    100% { opacity:1; }
}
@-o-keyframes loading-content-animation {
    0%   { opacity:1; }
    50%  { opacity:0.25; }
    100% { opacity:1; }
}
@-moz-keyframes loading-content-animation {
    0%   { opacity:1; }
    50%  { opacity:0.25; }
    100% { opacity:1; }
}
@-webkit-keyframes loading-content-animation {
    0%   { opacity:1; }
    50%  { opacity:0.25; }
    100% { opacity:1; }
}
.loading-content-animation-transition {
    -webkit-animation: loading-content-animation 1.5s infinite;
    -moz-animation: loading-content-animation 1.5s infinite;
    -o-animation: loading-content-animation 1.5s infinite;
    animation: loading-content-animation 1.5s infinite;
}

.loading-content-divider {
    width: 15.0rem;
    height: 2px;
    margin: auto;
    background-color: var(--cet-color-secondary-dark);
}
.loading-content-divider.loading-content-theme-white {
    background-color: var(--cet-color-base-white);
}


.loading-content-text-bread {
    color: var(--gaia-color-text-heading);
    font-family: var(--gaia-font-family-heading);
    font-weight: 700;
    word-wrap: break-word;
}
.loading-content-text-bread.loading-content-theme-white {
    color: var(--gaia-color-text-bread-light);
}

.loading-content-spacing-heading {
    padding-top: 2.0rem;
    padding-bottom: 1.0rem;
}
.loading-content-spacing-bread {
    padding-top: 1.0rem;
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .loading-content-container {
        text-align: center;
        padding: 2.5rem 0;
    }
    .loading-content-text-heading-size {
        font-size: 0.8rem;
    }
    .loading-content-text-bread-size {
        font-size: 0.8rem;
    }
}
/* Small devices (landscape phones, 576px and up but less than 768px) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .loading-content-container {
        text-align: center;
        padding: 3.0rem 0;
    }
    .loading-content-text-heading-size {
        font-size: 0.9rem;
    }
    .loading-content-text-bread-size {
        font-size: 0.9rem;
    }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .loading-content-container {
        text-align: center;
        padding: 6.0rem 0;
    }
    .loading-content-text-heading-size {
        font-size: 1.0rem;
    }
    .loading-content-text-bread-size {
        font-size: 1.0rem;
    }
}
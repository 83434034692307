.katex {
    font-size: 1.2em;
}

#methodology-container {
    padding: 0 2rem;
}

#methodology-container p {
    font-family: var(--gaia-font-family-bread);
    margin: 0.5rem 0.5rem 2rem;
}

#methodology-container h2 {
    font-family: var(--gaia-font-family-bread);
    margin-top: 2rem;
}

#methodology-container h4 {
    font-family: var(--gaia-font-family-bread);
    font-size: 1.3rem;
}

#methodology-container .nav-link {
    font-family: var(--gaia-font-family-bread);
    font-weight: bold;
    color: var(--gaia-color-text-bread-dark) !important;

}
.account-alias-modal-control.is-invalid, .account-alias-modal-control.was-validated .account-alias-modal-control:invalid {
    width: 90%;
}

.account-alias-modal-control {
    width: 90%;
    margin: 1.5rem auto;
    border: var(--gaia-button-border-width) solid var(--cet-color-secondary-light);
    box-shadow: none !important;
}

.account-alias-modal-control:focus {
    border: var(--gaia-button-border-width) solid var(--gaia-color-button-primary-bg);
}

.account-alias-modal-control-label {
    margin: 1.5rem 1.5rem;
}
.account-alias-modal-label{
    color: var(--tds-grey-958);
    font-size: 0.75rem;
}
.account-alias-modal-input-container{
    margin-top: 0.5rem;
    background-color: var(--tds-grey-50);
    border-bottom: 1px solid var(--tds-grey-600);
    display: flex;
    height: 3.5rem;
    padding: 0 1rem;
}

.account-alias-modal-input{
    border: none !important;
    outline: none !important;
    height: 100%;
    width:100%;
    background-color: transparent;
    font-size: 1rem;
}
.account-alias-modal-input-length-display{
    display: flex; 
    width:100%;
    justify-content: flex-end;
    font-size: 0.75rem;
    color: var(--tds-grey-958);
}

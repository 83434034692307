.filter-dropdown-base {
    position: absolute;
    z-index: 1020; /* Should be less than 1030 since that's top menu */

    background-color: var(--gaia-color-text-bread-light);

    border-radius: 0.75rem;
    box-shadow: var(--gaia-color-text-bread-lighter) 0px 20px 30px -10px;
    width: fit-content;
    min-width: 20.0rem;
    max-width: 20.0rem;

    font-family: var(--gaia-font-family-heading);
    font-size: 0.875rem;
}

.filter-dropdown-empty-content {
    padding: 1.5rem;
}

.filter-dropdown-divider {
    min-height: 1px;
    max-height: 1px;
    background-color: var(--cet-color-secondary-lighter);
}

.filter-dropdown-controls {
    padding: 1.0rem;
}

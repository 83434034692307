.date-picker-calendar-wrapper{
    position: absolute;
    z-index: 1020; /* Should be less than 1030 since that's top menu */
    background-color: var(--gaia-color-text-bread-light);
    border-radius: 0.75rem;
    box-shadow: var(--gaia-color-text-bread-lighter) 0px 20px 30px -10px;
    padding: 1rem;
}
.date-picker-calendar-wrapper.emissions{
    right: 0;
}

.date-picker-calendar-wrapper.company{
   right: 0rem;
}

.date-picker-calendar-wrapper.baseline{
    right: 0;
}

.date-picker-calendar-wrapper.assessment{
    right: 0;
}

@media(max-width: 1400px){
    .date-picker-calendar-wrapper.emissions{
        right: auto;
        left: 0;
    }
    .date-picker-calendar-wrapper.assessment{
        right: auto;
        left: 0;
    }
}

@media(max-width: 1200px){
    .date-picker-calendar-wrapper.emissions{
        left: -12rem;
    }
    .date-picker-calendar-wrapper.baseline{
        right: auto;
        left: 0;
    }
    .date-picker-calendar-wrapper.assessment{
        left: -15rem;
    }
}  

@media (max-width: 992px) {
    .date-picker-calendar-wrapper.emissions{
        left: 0;
    }
    .date-picker-calendar-wrapper.company{
        left: 0;
     }
     .date-picker-calendar-wrapper.baseline{
        left: -8rem;
    }
    .date-picker-calendar-wrapper.assessment{
        left: 0;
    }
}
@media (max-width: 768px) {
    .date-picker-calendar-wrapper{
        left: 0 !important;
    }
}


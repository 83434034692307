.cookie-disclaimer{
    position : fixed;
    bottom: 0;
    z-index: 2000;
    background-color: var(--cet-color-secondary);
    border-top: solid 3px var(--cet-color-secondary-dark);
    padding: 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
}
.cookie-disclaimer-accept-button, .cookie-disclaimer-reject-button {
    @media(max-width: 576px){
        width: 100%;
    }
}
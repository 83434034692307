.recommendations-relocation-col-image {
  position: absolute;
  border-left: solid 1px rgba(0, 0, 0, 0.25);
  background-image: url('./recommendations-electricitymap.png');
  background-repeat: no-repeat;
  background-size: cover;
}


.recommendations-relocation-col-container-image {
  position: relative;
  margin: 14rem;
  padding: 0.5rem;

}

.electricitymap-text-logo {
  position: absolute;
  margin-top: -28.5rem;
  margin-left: 0.25rem;
  max-height:30%;
  max-width: 30%;
  padding: 0.5rem;
  background-color: white;
  box-shadow: 0 0 0.5rem 0.25rem rgba(0, 0, 0, 0.25);
  font-size: 0.6rem;
}

.recommendations-intro-icon-image {
  font-size: 4rem;
}

.recommendations-rightsizing-text-quote {
  color: var(--gaia-color-text-heading);
}
.detailservice-cell-text-account-base {
    display: flex;
    flex-direction: column;
    
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}
.detailservice-cell-text-account-id {
    line-height: 1.2rem;
    font-size: 0.75rem;
}
.detailservice-cell-text-account-alias {
    line-height: 1.2rem;
    padding-bottom: 0.25rem;
}


.detailservice-footer-container {
    box-sizing:border-box;
    width: 100%;
    height: 3.0rem;
    background-color: var(--cet-color-secondary-lighter);
    border-color: var(--gaia-color-text-bread-lighter);
    border-width: 0 1px 1px 1px;
    border-style: solid;
    padding: 2rem 0.5rem;

    font-family: var(--gaia-font-family-bread);
    font-size: 0.9rem;
    font-weight: bold;
}

.detailservice-footer-column {
    width: 150px;
    padding-left: 0.75rem;
}

.detailservice-grid-container {
    width: 100%;
    height: 100%;
    /* display:flex;
    flex-direction:column;
    flex-grow: 1; */
    /* height: calc(100vh - var(--scania-footer-height)); */
    /* min-height: 20rem; */
}
.detailservice-grid-container {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
}

.ag-theme-alpine {
    --ag-alpine-active-color: transparent;
    --ag-row-hover-color: var(--tds-grey-200);
    --ag-foreground-color: var(--gaia-color-text-accordion-dark);
    --ag-background-color: var(--gaia-color-text-bread-light);
    --ag-header-foreground-color: var(--gaia-color-text-accordion-dark);
    --ag-header-background-color: var(--cet-color-secondary-lighter);
    --ag-odd-row-background-color: var(--cet-color-secondary-lighter);
    --ag-border-color: var(--gaia-color-text-bread-lighter);

    --ag-font-size: 0.8rem;
    --ag-font-family: var(--gaia-font-family-bread);
}


.detail-service{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.empty-content-container {
  text-align: center;
  padding: 6.0rem 0;
}

.empty-content-divider {
  width: 15.0rem;
  height: 2px;
  margin: auto;
  background-color: var(--gaia-color-text-heading);
}

.empty-content-text-heading {
  color: var(--gaia-color-text-heading);
  font-family: var(--gaia-font-family-heading);
  font-size: 1.5rem;
  font-weight: 700;
  word-wrap: break-word;
}

.empty-content-spacing-heading {
  padding-top: 2.0rem;
  padding-bottom: 1.0rem;
}

.empty-content-text-bread {
  color: var(--gaia-color-text-heading);
  font-family: var(--gaia-font-family-heading);
  font-size: 1.0rem;
  font-weight: 700;
  word-wrap: break-word;
}

.empty-content-spacing-bread {
  padding-top: 1.0rem;
  padding-bottom: 2.0rem;
}

.empty-content-text-bread-email {
  color: var(--cet-color-secondary-dark);
}
.empty-content-text-bread-email:hover {
  color: var(--cet-color-secondary-light);
}
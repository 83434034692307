.loading-modal-outer {
    position: fixed;
    z-index: 2000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #333333AA;
}

.loading-modal-base {
    position: fixed;
    z-index: 2001;
    bottom: 0;
    left: 0;
    right: 0;
}

.loading-modal-container {
    border-radius: 1.563rem;
    border: 0.125rem solid #333333;
    background: linear-gradient(90deg, var(--gaia-color-primary-600) 0%, var(--gaia-color-primary-500) 100%);
}

.loading-modal-column {
    background: url('./loading-background.svg');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: right bottom -5.0rem;
    border-radius: 1.563rem;

    text-align: center;
    

    padding: 0;
}
.loading-modal-text {
    font-family: var(--gaia-font-family-heading);
    font-size: 1.5rem;
    color: #333333;

    padding-top: 2rem;
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .loading-modal-base-top {
        top: 10%;
    }
    .loading-modal-container-size {
        width: 20rem;
    }
}
/* Small devices (landscape phones, 576px and up but less than 768px) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .loading-modal-base-top {
        top: 10%;
    }
    .loading-modal-container-size {
        width: 30rem;
    }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .loading-modal-base-top {
        top: 15%;
    }
    .loading-modal-container-size {
        width: 40rem;
    }
}
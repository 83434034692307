.line-chart-tooltip-base {
    position: fixed;
    z-index: 1000;
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #FFFFFF;
    font-size: 0.875rem;
    user-select: none;
}

.line-chart-tooltip-header {
    border-bottom: 1px solid #CCCCCC;
    background-color: #EEEEEE;
    font-weight: bold;
}

.line-chart-tooltip-color-circle {
    min-width: 0.75rem;
    min-height: 0.75rem;
    border-radius: 50%;
}
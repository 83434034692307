
@keyframes slideIn {
    from {
        max-height: 0;
    }
    to {
        max-height: 15.0rem;
    }
}
@keyframes slideOut {
    from {
        max-height: 15.0rem;
    }
    to {
        max-height: 0;
    }
}


.checkbox-item-list-label {
    padding: 0.5rem 0.75rem;
    font-size: 0.7rem;
    font-weight: bold;
    user-select: none;

    background-color: var(--gaia-color-tertiary-50);
}
.checkbox-item-list-label.cursor {
    cursor: pointer !important;
}
.checkbox-item-list-label:hover {
    background-color: var(--cet-color-secondary-lighter);
}

.checkbox-item-list-divider {
    min-height: 1px;
    max-height: 1px;
    background-color: var(--cet-color-secondary-lighter);
}

.checkbox-item-list-items {
    overflow-y: scroll;
    max-height: 0;

    /* Foreground, Background */
    scrollbar-color: var(--cet-color-secondary-light) var(--tds-grey-200);
}
.checkbox-item-list-items-show {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: slideIn;
}
.checkbox-item-list-items-hide {
    animation-delay: -0.5s;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: slideOut;
}
.checkbox-item-list-items::-webkit-scrollbar {
    width: 0.5rem; /* Mostly for vertical scrollbars */
    height: 0.5rem; /* Mostly for horizontal scrollbars */
}
.checkbox-item-list-items::-webkit-scrollbar-thumb { /* Foreground */
    background: var(--cet-color-secondary-light);
}
.checkbox-item-list-items::-webkit-scrollbar-track { /* Background */
    background: var(--tds-grey-200);
}
.detailcanvas-canvas {
    width: 100% !important;
    background-color: white !important;
    position: fixed;
    top: 4rem;
    left: 0;
    z-index: 100;
    display:flex;
    flex-direction:column;
    flex-grow: 1;
    height: calc(100vh - var(--scania-header-height));
    /* flex-direction */
}
.detail-canvas-header{
    display: flex; 
    justify-content:center !important;
    align-items: center;
    text-align: center;
    padding: 0 2rem;
}

.detailcanvas-canvas-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: -1.0rem;
    padding: 0 2rem;
}

.detailcanvas-export-icon {
    color: var(--cet-color-secondary-dark);
    cursor: pointer;
}
.detailcanvas-export-icon:hover {
    color: var(--gaia-color-secondary-600);
}

.detailcanvas-help-icon {
    cursor: help;
}

.detailcanvas-date-text-container {
    width: 4.75rem;
    text-align: center;
}
.detailcanvas-date-text-topic {
    font-family: var(--gaia-font-family-heading);
    font-size: 0.5rem;
}
.detailcanvas-date-text-main {
    font-family: var(--gaia-font-family-heading);
    font-weight: bold;
    font-size: 0.75rem;
}

.detailcanvas-date-navigation-column {
    margin-top: -0.25rem;
}

.detailcanvas-date-navigation-icon-disabled {
    height: 1.5rem;
    color: var(--gaia-color-text-bread-lighter);
    cursor: default;
}

.detailcanvas-date-navigation-icon {
    height: 1.5rem;
    color: var(--cet-color-secondary-dark);
    cursor: pointer;
}
.detailcanvas-date-navigation-icon:hover {
    color: var(--gaia-color-secondary-600);
}
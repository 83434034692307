.o-create-api-key-wrapper ul {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-left: 0;
    padding-left: 0;
}

.o-create-api-key-wrapper ul li {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
}

.o-create-api-key-wrapper ul > li::marker {
    font-size: 0.8rem;
}

/* --------new css--------------------- */
.footer-panel{
    /* position: sticky; */
    left: 0;
    bottom: 0;
    width: 100%;
}

/* .footer-navbar {
    background-color: var(--cet-color-secondary);
    padding: 0;
}

.footer-navbar-container {
    background-color: var(--cet-color-secondary);
    color: var(--gaia-color-text-bread-light);
    font-size: smaller;
    font-family: var(--gaia-font-family-bread);
}
.footer-navbar-container-height {
    height: var(--gaia-spacing-bottom-footer);
}

.footer-panel-link {
    display: block;
    text-decoration: none;
    color: var(--gaia-color-text-bread-light);
    border-left: 0.188rem solid transparent;
}

.footer-panel-link:hover {
    display: block;
    text-decoration: none;
    color: var(--gaia-color-text-bread-light);
    border-left: 0.188rem solid transparent;
} */
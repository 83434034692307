.filter-drop-down-calender-fixed-date{
    font-size:0.8rem;
    font-weight:400;
    padding:1rem 0.5rem 1rem 2.5rem;
    margin:0 -0.5rem 0 -1.5rem;
    border-bottom:1px solid #D8D8D8;
    
}
.filter-drop-down-calender-fixed-date:hover{
    background: #D8D8D8;
    cursor: pointer;
}

@media (max-width: 570px) {
    .filter-drop-down-calender-fixed-date{
        padding:0.5rem;
        border-radius:5px;
        border-bottom:0;
        background:var(--bs-gray-200);
    }
}
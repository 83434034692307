.trends-table-account-main-content{
    line-height: 1.2rem !important;
}
.trends-table-account-sub-content{
    margin-top:0.3rem;
    line-height: 0.9rem;
    font-size: 0.7rem;
}
.trends-table-trends-icon.arrow-down{
    color: green;
}
.trends-table-trends-icon.arrow-up{
    color: red;
}
.trends-table-export-icon {
    color: var(--cet-color-secondary-dark);
    cursor: pointer;
    display: flex;
    align-items: center;
}
.trends-table-export-icon:hover {
    color: var(--gaia-color-secondary-600);
}
/* Bootstrap 5 customization */
:root {
    --gaia-button-font-size: 1rem;
    --gaia-button-line-height: 1.2rem;
    --gaia-button-content-height: max(var(--gaia-button-font-size), var(--gaia-button-line-height));
    --gaia-button-padding-top-bottom: 0.6rem;
    --gaia-button-padding-left-right: 2.5rem;
    --gaia-button-border-width: 0.125rem;
    --gaia-button-border-radius: 1.5rem;
}

/******************************************************************************
 BOOTSTRAP CLASSES THAT WE USE. TODO: Phase out as we go over to Tegel
 DON'T IMPORT ALL OF BOOTSTRAP BECAUSE IT AFFECTS THE TEGEL COMPONENTS
 ******************************************************************************/

.px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
}

.p-4 {
    padding: 1.5rem !important;

}

.d-flex {
    display: flex !important;
}

.d-none {
    display: none !important;
}


.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important;
}

.flex-column {
    flex-direction: column !important;
}

.w-100 {
    width: 100% !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.me-auto {
    margin-right: auto !important;
}

.rounded {
    border-radius: var(--bs-border-radius) !important;
}

.text-center {
    text-align: center !important;
}

.p-0 {
    padding: 0 !important;
}

.px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}

.gap-1 {
    gap: 0.25rem !important;
}

.gap-2 {
    gap: 0.5rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pb-3 {
    padding-bottom: 1rem !important;
}

.ms-3 {
    margin-left: 1rem !important;
}

.me-2 {
    margin-right: 0.5rem !important;
}

.mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
}

.position-absolute {
}

.top-0 {
    top: 0 !important;
}

.start-0 {
    left: 0 !important;
}

.end-0 {
    right: 0 !important;
}

.align-items-center {
    align-items: center !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.ms-lg-5 {
    margin-left: 3rem !important;
}

.h-100 {
    height: 100% !important;
}

@import url('@scania/tegel/dist/tegel/tegel.css');

/* primary- blue, white & black
background-grey */

:root {

    /* Colors */
    /* primary-blue */
    --cet-color-primary-lighter: var(--tds-blue-100);
    --cet-color-primary-light: var(--tds-blue-500);
    --cet-color-primary: var(--tds-blue-800);/*This is Scania blue color- primary*/
    --cet-color-primary-dark: var(--tds-blue-900); 

    /* white */
    --cet-color-base-white: var(--tds-white);

    /* black */
    --cet-color-base-black: var(--tds-black);

    /* -----secondary grey---------- */
    --cet-color-secondary-lighter: var(--tds-grey-100);
    --cet-color-secondary-light: var(--tds-grey-300);
    --cet-color-secondary: var(--tds-grey-500);
    --cet-color-secondary-dark: var(--tds-grey-800);
    --cet-color-secondary-darker: var(--tds-grey-900);
    
    /* -----general---------- */
    --gaia-color-text-heading: var(--cet-color-secondary-dark);
    --gaia-color-text-bread-light: var(--cet-color-base-white);
    --gaia-color-text-bread-dark: var(--tds-grey-600);
    --gaia-color-text-bread-lighter: var(--tds-grey-400);
    --gaia-color-text-bread-alternative-light: var(--cet-color-secondary-dark);
    --gaia-color-text-bread-alternative-dark: var(--tds-grey-600);
    --gaia-color-text-accordion-dark: var(--cet-color-secondary-darker);
    --gaia-color-accordion-border: var(--tds-grey-200);

    /* Spacing */
    --gaia-padding-panel-container: 3rem 4.5rem;
    --gaia-padding-panel-container-slim-vertical: 1rem 4.5rem;

    --gaia-spacing-top-menu: 3.85rem;
    --gaia-spacing-bottom-footer: 3.0rem;
    --gaia-spacing-container-sides: 1rem;

    /* Button */
    --gaia-color-button-primary-bg: var(--cet-color-secondary-lighter);
    --gaia-color-button-primary-text: var(--cet-color-base-white);
    --gaia-font-family-button-primary: 'PT Sans';
    --gaia-color-button-primary-active-bg: var(--tds-grey-600);
    --gaia-color-button-primary-disabled-fg: var(--tds-grey-700);
    --gaia-color-button-primary-disabled-bg: var(--tds-grey-600);
    --gaia-color-button-primary-hover-bg: var(--cet-color-secondary-light);
    --gaia-color-button-primary-hover-text: var(--cet-color-secondary-darker);
    --gaia-color-button-primary-focus-bg: var(--tds-grey-200);
    --gaia-color-button-primary-focus-border: var(--tds-grey-600);

    /*footer*/
    --scania-footer-height: 200px;

    /*header*/
    --scania-header-height: 64px;
}
.blur {
    filter: blur(5px);
    -webkit-filter: blur(5px);
}
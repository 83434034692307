.date-picker-calendar-fixed-heading-text {
    font-family: var(--gaia-font-family-heading);
    font-size: 0.7rem;
    font-weight: bold;
    padding: 0.5rem;
}

.date-picker-calendar-fixed-heading-divider {
    min-height: 1px;
    max-height: 1px;
    width: 6.375rem;

    background-color: var(--gaia-color-tertiary-300);
}
 
.date-picker-calendar-fixed-date-container{
    width: 10rem;
    border-right: 1px solid var(--gaia-color-tertiary-200);
    margin-bottom: -3.75rem;
}

.date-picker-calendar-container-button{
    padding: 0.375rem 0.75rem;

    font-family: var(--gaia-font-family-heading);
    font-size: 0.875rem;
    font-weight: bold;

    letter-spacing: 0.05rem;
}

.date-picker-calendar-range-mobile{
    display: none
}

/* @media(max-width: 1400px){
    .date-picker-calendar{
        right: auto;
        left: 0;
    }
} */

/* @media(max-width: 1200px){
    .date-picker-calendar{
        left: -12rem;
    }
}   */

@media (max-width: 992px) {
    .date-picker-calendar-range-mobile {
        display: inline-block;
    }
    .date-picker-calendar-range {
        display: none;
    }
    /* .date-picker-calendar{
        left: 0;
    
    } */
}

@media (max-width: 768px) {
    .date-picker-calendar-fixed-date-container{
        width: 7rem;
    }
}

@media (max-width: 570px) {
    .date-picker-calendar-fixed-date-container{
        display: flex;
        height: 3rem;
        width: 100%;
        justify-content: center;
        gap: 4rem;
        border: none;
        margin: 0 0.5rem;
        align-items: center;
    }
    
    .date-picker-calendar-container{
        flex-direction: column;
        border: none;       
    }
}
.api-key-settings-container-small-screen{
    display:none;
    text-align: center;
    padding: 6.0rem 0;
}

    @media (max-width: 992px) {
    .api-key-settings-container {
       display:none;
    }
    .api-key-settings-container-small-screen{
        display:block;
    }
}
.api-key-table.ag-theme-alpine {
    font-Size: 0.875rem !important;
    font-weight: 600 !important;
    font-family: var(--gaia-font-family-heading);
    color: #000
}

.api-key-table .ag-header {
    display: none;
}

.api-key-table.ag-theme-alpine {
    --ag-row-border-width: 0;
}

.api-key-table-created-text-span {
    font-weight: 400 !important
}
